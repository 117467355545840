@import "@/styles/functions";
@import "@/styles/new-variables";

.commentForm {
  .textareaInput {
    margin-bottom: toRem(24);
    padding: toRem(12);
    border: 2px solid $grey-1;
    border-radius: toRem(12);
    resize: vertical;
    background-color: transparent;
    color: $white;
    // font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */

    &:focus {
      outline: 2px solid $primary;
    }
  }
}
