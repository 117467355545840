@import '@/styles/functions';
@import '@/styles/new-variables';

.listElement {
  cursor: pointer;
  display: grid;
  grid-template-columns: auto;
  align-items: center;
  padding: toRem(12) 0;
  gap: toRem(12);
  align-self: stretch;
  // color: $transparent-white-70;
  transition: all 300ms;

  &:hover {
    color: $primary;
    background-color: $dark;
  }

  &.active {
    color: $primary;
  }

  &.hasIcon {
    grid-template-columns: toRem(32) auto;
    padding: toRem(12) 0 toRem(12) toRem(16);

    &:not(.lastItem) {
      .contentWrapper::after {
        bottom: toRem(-19);
      }
    }
  }

  .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 2rem;
    width: 2rem;
    height: 2rem;
    background-color: $transparent-white-6;
    border-radius: toRem(8);
  }

  .contentWrapper {
    position: relative;
    width: 100%;
    padding-right: toRem(16);
  }

  &:not(.lastItem) {
    .contentWrapper::after {
      content: '';
      position: absolute;
      bottom: toRem(-13);
      right: 0;
      width: 100%;
      height: toRem(1);
      background-color: $transparent-white-6;
    }
  }
}
