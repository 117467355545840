@import "@/styles/variables";

.container {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.topPanel {
  text-align: center;
  margin-bottom: 2rem;
}

.bottomPanel {
  display: flex;
}

.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $light-dimmed-color-4;
  margin-top: 5rem;
}

.noResultsImage {
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .topPanel {
    display: flex;
    justify-content: space-between;
  }
}
