@import '@/styles/variables';

.mobileMenu {
  -webkit-box-pack: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: calc(85px + env(safe-area-inset-bottom));
  position: fixed;
  left: 0px;
  bottom: 0px;
  backdrop-filter: blur(10px);
  z-index: 1100;

  .mobileBottomNav {
    width: 100%;
    display: flex;
    align-items: stretch;
    border-top: 0.333px solid var(--transparrent-white-white-10, rgba(255, 255, 255, 0.1));
    background: $transparent-white-6;
    box-shadow: 0px -10px 30px 0px rgba(15, 15, 18, 0.4);
    backdrop-filter: blur(30px);
    padding-bottom: env(safe-area-inset-bottom);

    & li {
      flex: 1;
      color: var(--transparrent-white-white-30, rgba(255, 255, 255, 0.3));
    }

    .listItem {
      flex: 1;
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      flex: 1;
      text-align: center;
      font-size: 0.6875rem;
      font-weight: 600;

      .icon {
        width: 24px;
        height: 24px;
        font-size: 0.8rem;
      }
    }
  }
}

.active {
  color: $signature-color;
}

@media (min-width: 1200px) {
  .mobileMenu {
    display: none;
  }
}
