@import "@/styles/functions";
@import "@/styles/new-variables";

.sectionHeading {
  // font-family: "Segoe UI Variable Static Display";
  font-size: toRem(28);
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 36.4px */
  width: fit-content;

  // &.gradient {
  //   display: inline-flex;
  //   background-image: linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0.4) 100%);
  //   background-clip: text;
  //   -webkit-background-clip: text;
  //   -webkit-text-fill-color: transparent;
  // }

  @media (max-width: get-breakpoint-width("sm")) {
    font-size: toRem(18);
  }
}
