@import '@/styles/variables';
@import '@/styles/functions';

.filtersModal {
  .filtersHeader {
    font-size: 1.5rem;

    .filterTop {
      position: relative;
      width: 100%;
      display: flex;
    }

    .filtersButton {
      color: $red;
      font-size: toRem(14);
      font-weight: 500;
      line-height: 130%; /* 18.2px */

      &:disabled {
        opacity: 0.6;
      }
    }
  }

  .filtersBody {
    padding-top: 0;
    overflow: auto;
  }
  @media (min-width: 576px) {
    .filtersBody {
      overflow: visible;
    }
  }
}
