@import '@/styles/new-variables';
@import '@/styles/functions';

.selectLabel {
  all: unset;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: toRem(48);
  display: flex;
  align-items: center;
  padding: toRem(5) toRem(10) toRem(5) toRem(14);
  align-self: stretch;
  border: 1px solid $grey-2;
  background: $transparent-white-6;
  border-radius: toRem(12);
  overflow: hidden;

  &:hover {
    color: $white;
    border-color: $grey-2;
  }

  .selectLabelContent {
    width: calc(100% - 24px);
    display: flex;
    flex-direction: column;
  }
}

.dropdownItem {
  width: 100%;
  padding: 0 1rem;
}

.arrowClassName {
  display: flex;
  width: toRem(24);
  height: toRem(24);
  padding: toRem(4);
  border-radius: toRem(40);
  background: $grey-3;
  transform: rotateZ(-90deg);
}

.rotate {
  transform: rotateZ(90deg);
}

.dropdownMenu {
  width: 100%;
}

.selectModalWrapper {
  .modalHeader {
    border-bottom: 1px solid $transparent-white-6;
  }

  .modalContainer {
    padding: 0;
    max-height: 60vh;
    overflow-y: auto;

    .modalOption {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));

      & > li,
      div {
        height: 100%;
        padding: 0;
        &::after {
          bottom: 0;
        }
      }

      .dropdownItem {
        & > label {
          box-sizing: border-box;
          padding: 0.75rem;
        }
      }

      .checkedIconContainer {
        display: flex;
        width: toRem(24);
        height: toRem(24);
        padding: toRem(4);
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: toRem(6);
        border: 1px solid var(--transparrent-white-white-10, rgba(255, 255, 255, 0.1));
        background: var(--transparrent-white-white-10, rgba(255, 255, 255, 0.1));

        &.checked {
          background: var(--Alerts-Green, #34c763);
        }
      }
    }
  }

  .modalButton {
    width: 100%;
  }
}

.textContainer {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
  color: $transparent-white-50;
  font-size: toRem(12);
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 15.6px */
  text-wrap: nowrap;
}
