@import "@/styles/variables";
@import "@/styles/new-variables";
@import "@/styles/functions";

.socialGroupTitle {
  display: flex;
  gap: toRem(12);
  align-items: center;
  margin-bottom: 1rem;
  color: $grey-4;
  font-size: toRem(14);
  font-weight: 500;
  line-height: 130%;
  
  &::before,&::after {
    content: "";
    flex-grow: 1;
    display:block;
    height: 1px;
    background-color: $grey-3;
    margin: 0 0.5rem;
  }
}

.socialButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: toRem(12);
  background: $transparent-white-6;
  width: toRem(50);
  height: toRem(50);
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: toRem(12);
}
