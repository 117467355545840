@import "src/styles/functions.scss";
@import "src/styles/new-variables";

.paymentStatusWrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .statusActions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
.writeOff {
  color:  $alert-green;
  font-size: toRem(20);
  font-weight: 600;
  line-height: 130%; 
}
