@import "@/styles/functions";
@import "@/styles/new-variables";

.userCredentialsBase {
  display: inline-flex;
  align-items: center;
  gap: 1rem;

  .avatarWrapper {
    position: relative;
  }

  .mainCredentials {
    font-size: 0.8125rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;

    .login {
      color: $white;
      font-style: normal;
      font-size: 1rem;
      font-weight: 500;
      line-height: 130%; /* 1.95rem */
    }

    .phoneNumber {
      color: $grey-4;
      font-style: normal;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 130%; /* 1.4625rem */
    }
  }

  .userAvatar {
    width: toRem(60);
    height: toRem(60);
    flex-shrink: 0;
    font-size: 1.5rem;
  }
}

@media (min-width: get-breakpoint-width("sm")) {
  .userCredentialsBase {
    gap: 1.5rem;

    .userAvatar {
      width: 6.25rem;
      height: 6.25rem;
      font-size: 2rem;
    }

    .mainCredentials {
      .login {
        font-size: 1.5rem;
      }

      .phoneNumber {
        font-size: 1.125rem;
      }
    }
  }
}
