@import "@/styles/functions";
@import "@/styles/new-variables";

.sessionsModal {
  max-height: calc(80vh - 87px);
  overflow-x: hidden;
  overflow-y: auto;
}

.listItem {
  border: 1px solid $transparent-white-10;
  margin-top: toRem(16);
}
