@import "@/styles/functions";
@import "@/styles/new-variables";

.commentModal {
  max-width: toRem(382);
  max-height: 65vh;
  overflow-y: auto;
}

.commentModalHeader {
  color: $white;
  // font-family: "Segoe UI Variable Static Display";
  font-size: toRem(18);
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 23.4px */
  border-bottom: 1px solid $grey-1;
}
