@import '@/styles/variables';
@import '@/styles/functions';
@import '@/styles/new-variables';

.tabs {
  overflow-x: auto;

  .tabsSwitchersWrapper {
    margin-bottom: toRem(20);
    overflow-x: auto;
  }

  .tabsSwitchers {
    display: flex;
    gap: toRem(8);
    overflow-x: auto;
  }

  .tabsSwitchers.secondary {
    display: flex;
    padding: toRem(4);
    align-items: center;
    gap: toRem(4);
    border-radius: toRem(10);
    background: $transparent-white-10;
  }

  .tabsSwitchers.primary {
    color: #ffffff;
  }

  .tabsSwitcher {
    display: inline-flex;
    font-weight: 600;
    position: relative;
    white-space: nowrap;
    gap: toRem(4);

    .count {
      display: flex;
      padding: toRem(1) toRem(5);
      justify-content: center;
      align-items: center;
      gap: toRem(10);
      border-radius: toRem(8);
      background: rgba(15, 15, 18, 0.1);
    }
  }

  .tabsSwitcher[data-focus-visible-added] {
    z-index: 1;
  }

  .tabsSwitcher.secondary {
    display: flex;
    padding: toRem(3) 1rem toRem(5) 1rem;
    justify-content: center;
    align-items: center;
    gap: toRem(4);
    flex: 1 0 0;
  }

  .tabsSwitcher.secondary.active {
    color: $dark-color-1;
    border-radius: toRem(6);
    background: $white;
    z-index: 2;
  }

  .tabsSwitcher.primary {
    border-radius: toRem(40);
    border: 1px solid rgba(255, 255, 77, 0.05);
    font-size: 0.9375rem;
    padding: toRem(7) toRem(12);
    text-align: center;
    font-size: toRem(13);
    font-weight: 600;
    line-height: 1rem; /* 123.077% */
    color: $transparent-white-50;
  }

  .tabsSwitcher.primary.active {
    color: $black;
    background: $primary;
  }

  @media (min-width: 576px) {
    .tabsSwitchers {
      overflow-x: visible;
    }
  }
}
