@import "@/styles/new-variables";
@import "@/styles/functions";

.googleButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: toRem(12);
  background: $transparent-white-6;
  width: toRem(50);
  height: toRem(50);
}

.googleIcon {
  width: 28px;
  height: 28px;
}
