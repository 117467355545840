@import "@/styles/new-variables";

.avatarBase {
  width: 2.75rem;
  height: 2.75rem;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 1rem;
  font-weight: bold;
  border: 2px solid $transparent-white-10;
  backdrop-filter: blur(10px);
}

.signature {
  color: $black;
  background-color: $primary;
}

.dimmed {
  color: $white;
  background-color: $black;
}
