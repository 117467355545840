@import '@/styles/functions';

.bage {
  display: flex;
  padding: toRem(1) toRem(6) toRem(3) toRem(6);
  justify-content: center;
  align-items: center;
  gap: toRem(10);
  border-radius: toRem(8);
  background: rgba(15, 15, 18, 0.1);
}
