@import '@/styles/functions';
@import '@/styles/new-variables';

.dropdownLabel {
  position: relative;
  display: flex;
  padding: 0.375rem 0rem;
  justify-content: center;
  align-items: center;
  gap: toRem(2);
  height: 100%;
  font-size: toRem(12);
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 15.6px */
  text-transform: uppercase;
  color: $transparent-white-50;
  transition: color 300ms;

  .chevronDownIcon {
    color: $grey-3;
    transition: color 300ms;
  }

  & .active {
    transform: rotateZ(180deg);
  }

  &:hover {
    color: $primary;
    border-color: $primary;

    .chevronDownIcon {
      color: $primary;
    }
  }
}
