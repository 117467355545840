@import '@/styles/functions';
@import '@/styles/new-variables';

$gap: toRem(12);

.sectionHeadingWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 toRem(15);

  .sectionHeading {
    display: flex;
    align-items: center;
    gap: toRem(8);

    .icon {
      color: $grey-4;
    }
  }
}

.episodesList {
  margin-top: toRem(12);

  @media (min-width: get-breakpoint-width('md')) {
    margin-top: toRem(16);
  }

  .episodesTabWrapper {
    display: flex;
    white-space: nowrap;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: toRem(12) 0 0;

    @media (min-width: get-breakpoint-width('md')) {
      padding: toRem(20) 0 toRem(4);
    }

    &.seasonsFetched > li {
      width: fit-content;
      flex: 0;
    }
  }

  .episodSlide {
    margin-right: $gap;

    @media (min-width: get-breakpoint-width('md')) {
      max-width: calc((100% - 2 * $gap) / 3);
    }

    @media (min-width: get-breakpoint-width('lg')) {
      margin-right: toRem(18);
      max-width: calc((100% - 2 * toRem(18)) / 3);
    }

    @media (min-width: get-breakpoint-width('xl')) {
      margin-right: toRem(20);
      max-width: calc((100% - 3 * toRem(20)) / 4);
    }
  }
}
