@import "@/styles/functions";
@import "@/styles/new-variables";

.profileDropdown {
  .listItem {
    position: relative;
    overflow: hidden;

    &:not(:last-child) {
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: toRem(-56);
        width: 100%;
        height: 1px;
        background-color: $transparent-white-6;
      }
    }
  }

  .dropdownItem {
    display: flex;
    align-items: center;
    gap: toRem(12);
    width: 17.5625rem;
    padding: toRem(12) toRem(16);
    color: $transparent-white-70;
    // font-family: Inter;
    font-size: toRem(14);
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 18.2px */
    transition-property: color, background-color;
    transition-duration: 300ms;

    &:hover {
      color: $primary;
    }

    .iconWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      height: 2rem;
      border-radius: 0.5rem;
      background-color: $transparent-white-6;
    }

    &.active {
      color: $primary;

      .iconWrapper {
        background-color: $transparent-yellow-10;
      }
    }
  }
}
