@import "@/styles/variables";
@import "@/styles/new-variables";

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: toRem(50);
  height: toRem(50);
  border-radius: toRem(10);
  background: linear-gradient(191deg, rgba(255, 255, 77, 0.1) -46.82%, rgba(244, 102, 0, 0.1) 201%);
  color: $primary;

  &.currentSessionIcon {
    color: $white;
    background: linear-gradient(191deg, #ffff4d -46.82%, #f46600 201%);
  }

  &.alertIcon {
    width: toRem(80);
    height: toRem(80);
    margin: 0 auto toRem(20);

    svg {
      width: toRem(48);
      height: toRem(48);
    }
  }
}

.sessionCard {
  display: grid;
  grid-template-columns: toRem(50) calc(100% - toRem(62));
  align-items: center;
  gap: toRem(12);
  width: 100%;
  border-radius: 1rem;
  background: $grey-1;
  padding: 1rem;

  &.currentSession {
    pointer-events: none;
  }

  .infoWrapper {
    position: relative;
    display: flex;
    flex-direction: column;

    .terminateButtton {
      position: absolute;
      top: toRem(-8);
      right: toRem(-8);
      display: flex;
      align-items: center;
      justify-content: center;
      width: toRem(28);
      height: toRem(28);
    }

    .title {
      text-align: left;
      color: $white;
      // font-family: "Segoe UI Variable Static Display";
      font-size: toRem(15);
      font-style: normal;
      font-weight: 600;
      line-height: 130%; /* 19.5px */
      margin-bottom: toRem(8);
    }

    .dateWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .sessionDate {
        color: $transparent-white-30;
        // font-family: Inter;
        font-size: toRem(12);
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 15.6px */
      }
    }
  }
}

.sessionsInfoWrapper {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  background-color: $grey-2;
  padding: 0 1rem;
  border-radius: 1rem;
  margin-top: toRem(14);

  & > li {
    padding: toRem(12) 0;
    &:not(:last-child) {
      border-bottom: 1px solid $transparent-white-6;
    }
  }

  .infoLabel {
    color: $transparent-white-30;
    // font-family: Inter;
    font-size: toRem(12);
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
    margin-bottom: toRem(2);
  }

  .labelValue {
    color: $white;
    // font-family: Inter;
    font-size: toRem(14);
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 18.2px */
  }
}
