@import "@/styles/functions";
@import "@/styles/new-variables";

.commentCard {
  padding: 1rem;
  border-radius: 1rem;
  background-color: $grey-1;
  height: 100%;
  flex-grow: 1;

  .readMoreButton {
    color: $primary;
    text-align: right;
    // font-family: Inter;
    font-size: toRem(14);
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    margin-top: toRem(12);
  }
}

.topPanel {
  display: flex;
  align-items: center;
  gap: toRem(12);
  padding-bottom: toRem(12);
  margin-bottom: toRem(12);
  border-bottom: 1px solid $grey-2;

  &.borderNone {
    border: none;
    padding-bottom: 0;
  }

  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: toRem(48);
    height: toRem(48);
    border: 1px solid $transparent-white-10;
    border-radius: 50%;
  }

  .authorName {
    color: $white;
    // font-family: "Segoe UI Variable Static Display";
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 20.8px */
    margin-bottom: toRem(4);
  }

  .date {
    color: $grey-4;
    // font-family: Inter;
    font-size: toRem(12);
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
  }
}

.commentText {
  color: $white;
  // font-family: Inter;
  font-size: toRem(14);
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}
