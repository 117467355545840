@import '@/styles/new-variables';
@import '@/styles/functions';

.mobileMenu {
  display: flex;
  visibility: visible;
  align-items: center;
  justify-content: space-between;
  height: $mobile-header-height;

  .menuContainer {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    position: relative;
  }

  .searchTogglerContainer {
    display: flex;
    z-index: $search-icon-button-z-index;
  }
  .rightside{
    display: flex;
    align-items: center;
    gap: toRem(20);
  }
}

.notificationsIcon {
  display: flex;
  padding: 0.375rem;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.625rem;
  border: 1px solid $transparent-white-10;
}

.mobileHeaderLogo {
  width: toRem(177);
}

@media (min-width: 1200px) {
  .mobileMenu {
    display: none;
    visibility: hidden;
  }
}
