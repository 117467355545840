@import "@/styles/functions";
@import "@/styles/new-variables";

.primaryNav {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 toRem(6);

  &::after,
  &::before {
    content: "";
    position: absolute;
    width: 0.0625rem;
    height: 2rem;
    background: $transparent-white-10;
  }

  &::after {
    left: 0;
  }

  &::before {
    right: 0;
  }

  .navLink {
    display: flex;
    padding: toRem(6) toRem(8);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    color: $transparent-white-50;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 130%; /* 0.975rem */
    transition: color 0.3s;

    &:hover {
      color: $primary;
    }
  }

  .active {
    & > .activeIcon {
      color: $primary;
    }

    color: $white;
  }
}
