@import '@/styles/variables';

$border-radius: calc(1rem - 2px);

.categoryCard {
  border: 1px solid $transparent-white-6;
  backdrop-filter: blur(15px);
  border-radius: 0.5rem;

  &.image {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;

    .categoryImage {
      border-radius: 8px;
      overflow: hidden;
      z-index: 0;
    }
  }
}

.skeltonImage {
  position: relative;
  overflow: hidden;
  color: transparent;

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.categoryTitle {
  position: absolute;
  top: 0.625rem;
  left: 0.625rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 130%;
  z-index: 10;
}
