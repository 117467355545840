@import "@/styles/variables";
@import "@/styles/new-variables";

.profileWrapper {
  height: auto;
  background: $grey-1;
  padding: 1.25rem;

  .topPanelContent {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1.25rem;

    .topPanelHeader {
      font-size: 1rem;
      text-align: center;
    }

    .editButton {
      font-size: 0.95rem;
    }
  }

  .editText {
    display: none;
    visibility: hidden;
  }

  .credentialsPanel {
    padding: 1.5rem 1rem;
  }
}

@media (min-width: get-breakpoint-width("md")) {
  .profileWrapper {
    padding: 1.5rem;

    .editText {
      display: inline-block;
      visibility: visible;
    }
  }
}
