@import "@/styles/functions";
@import "@/styles/new-variables";

.playerModal {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $black;
  max-width: 100vw !important;
  width: 100vw !important;
  height: 100vh;
  transform: unset;
}

.playerModalInner {
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.playerModalHeader {
  // font-family: Inter;
  font-size: toRem(20);
  line-height: toRem(32);
  font-weight: 600;
  padding: 0.5rem;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.playerModalBody {
  padding: 0.5rem;
  padding-top: 0;
}

.playerWrapper {
  position: relative;
  width: 100%;

  .playerSlot {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}

@media (min-width: get-breakpoint-width("sm")) {
  .playerModalHeader {
    padding: 1rem;
    font-size: toRem(32);
    line-height: toRem(48);
  }

  .playerModalBody {
    padding: 1rem;
    padding-top: 0;
  }
}
