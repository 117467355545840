@import '@/styles/variables';
@import '@/styles/functions';

.formWrapper {
  .saveButton {
    display: block;
    margin-left: auto;
    padding: 6px 82px;

    @media (max-width: get-breakpoint-width('sm')) {
     width: 100%;
    }
  }
  .errorMessage {
    padding-top: 0.25rem;
  }

  .label {
    padding-bottom: 0.25rem;
    font-size: 0.95rem;
    color: $light-dimmed-color-3;
  }

  .input {
    background: $transparent-white-6;
  }
}
