@import '@/styles/variables';
@import '@/styles/functions';

.label {
  display: inline-block;
  font-size: 0.875rem;
  color: $light-dimmed-color-4;
  margin-bottom: toRem(10);
  user-select: none;
  color: $grey-4;
  font-size: toRem(14);
  font-weight: 500;
  line-height: 130%; /* 18.2px */

  &.isDisabled {
    opacity: 0.5;
  }
}
