@import '@/styles/functions';
@import '@/styles/new-variables';

.dropdown {
  position: absolute;
  width: max-content;
  z-index: 9;
  border-radius: 1rem;
  border: 1px solid $transparent-white-6;
  background: $black;
  backdrop-filter: blur(15px);
  color: $transparent-white-70;
  overflow: hidden;

  .dropdownList {
    min-height: toRem(52);
    max-height: toRem(300);
    overflow-y: auto;
  }
}

.bottom-right {
  top: 100%;
  right: 0;
  margin-top: 0.5rem;
}

.bottom-left {
  top: 100%;
  left: 0;
  margin-top: 0.5rem;
}

.top-right {
  bottom: 100%;
  right: 0;
  margin-top: 0.5rem;
}

.top-left {
  bottom: 100%;
  left: 0;
  margin-top: 0.5rem;
}
