.customToastBody {
  color: var(--Main-White, #fff);
  font-size: 13px;
  font-weight: 500;
  line-height: 130%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.notification {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 12px !important;
}
.success {
  border: 1px solid rgba(52, 199, 99, 0.1);
  background: rgba(52, 199, 99, 0.2) !important;
  box-shadow: 0px 8px 30px 0px rgba(15, 15, 18, 0.4);
  backdrop-filter: blur(15px);
}

.error {
  border: 1px solid rgba(255, 77, 77, 0.1);
  background: rgba(255, 77, 77, 0.2) !important;
  box-shadow: 0px 8px 30px 0px rgba(15, 15, 18, 0.4);
  backdrop-filter: blur(15px);
}

.iconWrapper {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50px;
}

.successIcon {
  background: var(--Alerts-Green, #34c763);
}

.errorIcon {
  background: var(--Alerts-Red, #ff4d4d);
}
