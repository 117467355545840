.listBase {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;

  & > a,
  button,
  li {
    width: 100%;
  }
}
