@import "@/styles/new-variables";
@import "@/styles/functions";

.alertModal {
  width: toRem(382);
  max-height: 65vh;
  overflow-y: auto;
}

.alertIcon {
  color: $primary;
}

.alertFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > button {
    flex: 1;
  }
}

.title {
  color: $white;
  text-align: center;
  font-size: toRem(20);
  font-weight: 600;
  line-height: 130%; /* 26px */
  padding-bottom: toRem(6);
}

.descritption {
  color: $grey-4;
  text-align: center;
  font-size: toRem(14);
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  padding-bottom: toRem(20);
}

.status {
  margin: 0 auto toRem(20);
  display: flex;
  width: toRem(70);
  height: toRem(70);
  padding: toRem(15);
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  &.error {
    border: 2px solid rgba(255, 255, 255, 0.4);
    background: $red;
  }

  &.warning {
    border: 2px solid rgba(255, 255, 255, 0.1);
    background: linear-gradient(192deg, #ffff4d -23.04%, #f46600 193.98%);
  }
  &.success {
    border: 2px solid rgba(255, 255, 255, 0.4);
    background: $alert-green;
  }
}
