@import "@/styles/functions";
@import "@/styles/new-variables";

%flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.placeholder {
  height: 100%;
  @extend %flex-center;

  .placeholderWrapper {
    text-align: center;
    margin: toRem(40) 0;

    .notFoundImage {
      padding-bottom: 1rem;
    }

    .title {
      color: $white;
      font-size: toRem(24);
      font-weight: 600;
      line-height: 130%; /* 31.2px */
      padding-bottom: toRem(6);
    }

    .description {
      color: $transparent-white-30;
      font-size: 1rem;
      font-weight: 400;
      line-height: 130%; /* 20.8px */
    }

    .notFoundButton {
      margin-top: 1rem;
    }
  }
}
