@import '@/styles/functions';
@import '@/styles/new-variables';
@import '@/styles/variables';

.codeInput {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: toRem(10);
  font-size: toRem(22);

  .codeInput {
    height: toRem(62);
    display: flex;
    border: 1px solid $grey-2;
    background: $transparent-white-6;
    border-radius: toRem(12);
    color: $white;
    text-align: center;

    @media (min-width: get-breakpoint-width('lg')) {
      width: toRem(50);
    }

    &:focus-visible {
      outline: none;
      border-color: $primary;
    }

    &.error {
      border-color: $red;
      background: rgba(255, 77, 77, 0.1);
    }
  }
}
