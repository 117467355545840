@import '@/styles/variables';
@import '@/styles/functions';

.form {
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  &.inactive {
    pointer-events: none;
  }

  .formHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;

    @media (min-width: get-breakpoint-width('lg')) {
      margin-bottom: 3rem;
    }
  }

  .formTitle {
    font-size: $form-title-mobile-font-size;
    font-weight: $form-title-font-weight;
    text-align: center;
  }

  .formAlert {
    display: inline-block;
    font-size: $form-alert-mobile-font-size;
    font-weight: $form-alert-font-weight;
    padding: $form-alert-mobile-padding;
    border-radius: 4px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;

    &.warning {
      background-color: $form-alert-warning-bg-color;
      color: $form-alert-warning-text-color;
    }

    &.error {
      background-color: $form-alert-error-bg-color;
      color: $form-alert-error-text-color;
    }
  }

  .formPanel {
    display: flex;
    align-items: center;
    padding: 0.5rem 0 0.5rem 0.6rem;

    &.flexBetween {
      justify-content: space-between;
    }

    &.flexEnd {
      justify-content: flex-end;
    }
  }

  .formFooter {
    width: 100%;
  }

  @media (min-width: 1200px) {
    margin: 0;

    .formHeader {
      align-items: flex-start;
    }

    .formTitle {
      font-size: $form-title-desktop-font-size;
      text-align: left;
    }

    .formAlert {
      font-size: $form-alert-desktop-font-size;
      padding: $form-alert-desktop-padding;
    }
  }
  .formLabel {
    & > .title {
      color: var(--Greys-Grey-4, #878788);
      font-size: toRem(14);
      font-weight: 500;
      line-height: 130%; /* 18.2px */
      padding-bottom: toRem(10);
    }
  }
}
