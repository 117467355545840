@import '@/styles/variables';

.spinnerBase {
  border: 2px solid;
  border-radius: 50%;
  animation: rotation 1s linear infinite;

  &.light {
    border-color: $white transparent;
  }

  &.signature {
    border-color: $signature-color transparent;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
