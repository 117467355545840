@import "@/styles/functions";
@import "@/styles/new-variables";

.topPanel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: toRem(24);
  margin-bottom: toRem(24);
}
