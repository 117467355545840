@import '@/styles/variables';
@import '@/styles/functions';

.paymeWrapper {
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .paymeForm {
    width: 50%;
    display: flex;
    height: toRem(64);
    padding: toRem(20);
    justify-content: center;
    align-items: flex-start;
    gap: toRem(38);
    flex-shrink: 0;
    border-radius: toRem(12);
    border: 1px solid rgba(52, 199, 99, 0.5);
    background: rgba(52, 199, 99, 0.1);
  }
}

.bage {
  display: flex;
  padding: toRem(1) toRem(6) toRem(3) toRem(6);
  justify-content: center;
  align-items: center;
  gap: toRem(10);
  border-radius: toRem(8);
  background: rgba(15, 15, 18, 0.1);
}
