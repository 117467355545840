@import '@/styles/new-variables';
@import '@/styles/functions';

@mixin nowrapText {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  text-wrap: nowrap;
}

.singleValue {
  color: $white;
  font-size: 1rem;
  font-weight: 500;
  line-height: 130%; /* 20.8px */
  @include nowrapText();
}

.multiValueContainer {
  display: flex;
  overflow: auto;
  gap: toRem(8);
}

.multiValue {
  color: $transparent-white-50;
  font-size: toRem(12);
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 15.6px */
  @include nowrapText();
}
