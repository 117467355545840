@import '@/styles/variables';
@import '@/styles/functions';

.filterWrapper {
  .filters {
    max-width: 100%;

    .switchers {
      margin: 0;
    }

    .scrollable {
      overflow-x: auto;
      flex-wrap: nowrap;
    }

    .extraFiltersWrapper {
      display: flex;
      gap: 1rem;
    }

    .mobileSubmit {
      width: 100%;
    }
  }
}

.selectMenuMultiColumns {
  padding: 0.5rem 0;
  & > ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    & > li div {
      &::after {
        display: none;
      }
    }
  }
}

.selectMenuColumn {
  padding: 0.5rem 0;
  & > ul {
    overflow-x: hidden;
    & > li div {
      &::after {
        display: none;
      }
    }
  }
}
