@import '@/styles/functions';
@import '@/styles/new-variables';
@import '@/styles/variables';

.formGroup {
  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  .formField {
    border: 1px solid $grey-2;
    background: $transparent-white-6;
    display: flex;
    align-items: stretch;
    border-radius: toRem(12);
    height: toRem(44);

    &.focused {
      border-color: $primary;
    }

    &.hasError {
      border: 1px solid $red;
      background: rgba(255, 77, 77, 0.1);
    }
  }
  .formLabel {
    display: inline-block;
    color: $grey-4;
    font-size: toRem(14);
    font-weight: 500;
    line-height: 130%;
    margin-bottom: toRem(10);
  }

  .phoneLabel {
    gap: toRem(10);
  }

  .label,
  .phoneLabel,
  .passwordViewLabel {
    display: flex;
    align-items: center;
    color: $form-input-label-color;
    position: relative;
    cursor: pointer;

    &.right {
      padding-right: toRem(10);
    }

    &.left {
      padding-left: toRem(10);
    }

    .labelIcon {
      color: inherit;
    }

    .passwordIcon {
      margin-right: 0.5rem;
    }

    &.notEmpty .labelIcon {
      color: $form-input-text-color;
    }

    &.hasError .labelIcon {
      color: $form-input-error-text-color;
    }
  }

  .formInput {
    width: 100%;
    height: 100%;
    font-size: $form-input-font-size;
    color: $form-input-text-color;
    border-radius: toRem(12);
    outline: none;
    color-scheme: dark;
    padding: toRem(10);

    &.hasIcon {
      padding: toRem(10) toRem(10) toRem(10) toRem(6);
    }

    /* Input sizes */
    &.lg {
      font-size: toRem(15);
      padding: toRem(12);
    }

    &.md {
      font-size: toRem(14);
      padding: toRem(6) toRem(12);
    }

    &.sm {
      font-size: toRem(12);
      padding: toRem(4) toRem(10);
    }
  }

  .error {
    font-size: $form-input-error-font-size;
    color: $form-input-error-text-color;
  }
}

.formInput.passwordInput {
  font-size: 24px;
}
