@import '@/styles/variables';
@import '@/styles/functions';
@import '@/styles/new-variables';

.userInfoLine {
  padding-bottom: 0.75rem;

  .infoLine {
    width: 100%;
    display: flex;

    &.row {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &.column {
      flex-direction: column;
      align-items: flex-start;
      gap: toRem(10);

      & > * {
        width: 100%;
      }
    }
  }

  .label {
    max-width: 100%;
    word-wrap: break-word;
    overflow-wrap: break-word;
    text-align: start;
    color: $grey-4;
    font-size: 0.725rem;
    font-weight: 500;
    line-height: 130%; /* 1.1375rem */
  }

  .value {
    color: $white;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 130%; /* 1.3rem */
  }

  &.bordered {
    border-bottom: 1px solid $transparent-white-6;
    margin-bottom: toRem(12);
  }

  &.lastItem {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

@media (min-width: get-breakpoint-width('sm')) {
  .userInfoLine {
    .label {
      font-size: 0.875rem;
    }

    .value {
      font-size: 1rem;
    }
  }
}
