@import '@/styles/new-variables';
@import '@/styles/functions';

.selectDropdownWrapper {
  .selectLabel {
    all: unset;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    align-self: stretch;
    border: 1px solid $grey-2;
    background: $transparent-white-6;
    border-radius: toRem(12);

    .selectLabelContent {
      width: calc(100% - 24px);
      display: flex;
      flex-direction: column;
    }

    &.primary {
      position: relative;
      height: toRem(48);
      padding: toRem(5) toRem(10) toRem(5) toRem(14);
      overflow: hidden;
    }

    &.secondary {
      min-height: toRem(42);
      padding: toRem(12);
    }

    &:hover {
      color: $white;
      border-color: $grey-2;
    }

    .selectLabelContent {
      width: calc(100% - 24px);
      display: flex;
      flex-direction: column;
    }

    &.activeDropdownLabel {
      background: $transparent-white-15;
    }
  }

  .dropdownItem {
    text-align: start;
    padding: 0 1rem;
  }

  .arrowClassName {
    position: absolute;
    width: toRem(24);
    height: toRem(24);
    right: toRem(12);
    display: flex;
    padding: toRem(4);
    align-items: center;
    gap: toRem(10);
    transition:
      background 0.3s,
      transform 0.3s;

    &.primary {
      border-radius: toRem(40);
      transform: rotateZ(-90deg);
    }

    &.secondary {
      border-radius: toRem(40);
      transform: rotateZ(-90deg);
    }

    &.active {
      background: $grey-3;
      transform: rotateZ(0deg);
    }
  }

  .dropdownMenu {
    background: #272728;
  }
}
