@import "@/styles/functions";

.contentWrapper {
  margin-top: toRem(24);
}

.userHistoryHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: toRem(20);
}

@media (min-width: get-breakpoint-width("lg")) {
  .contentWrapper {
    margin-top: toRem(18);
  }
}
