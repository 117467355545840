@import '@/styles/variables';

.mainHeader {
  display: block;
  width: 100%;
  position: fixed;
  z-index: $header-z-index;

  &::after {
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    backdrop-filter: blur(30px);
    background: $mobile-header-bg-color;
  }

  @media (min-width: 992px) {
    &::after {
      background: $desktop-header-bg-color;
      backdrop-filter: blur(10px);
    }
  }
}
