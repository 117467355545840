@import '@/styles/variables';

.formCheckbox {
  display: flex;
  align-items: center;
  user-select: none;

  .checkbox {
    transform: scale(0);
    position: absolute;
  }

  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .checkedIconContainer {
    display: flex;
    align-items: center;
    user-select: none;
    width: toRem(24);
    height: toRem(24);
    padding: toRem(4);

    border: 1px solid $transparent-white-10;
    background: $transparent-white-10;
    z-index: 2;

    &.round {
      border-radius: toRem(6);
    }

    &.circle {
      border-radius: 50%;
    }

    &.checked {
      background-color: $signature-color;
      border: 1px solid var(--transparrent-white-white-10, rgba(255, 255, 255, 0.1));
      background: $primary;
      color: $black;
    }
  }

  .tabsCheckbox {
    display: flex;
    border-radius: toRem(40);
    border: 1px solid rgba(255, 255, 77, 0.05);
    font-size: 0.9375rem;
    padding: toRem(7) toRem(12);
    text-align: center;
    font-size: toRem(13);
    font-weight: 600;
    line-height: 1rem; /* 123.077% */
    color: $white;

    &.checked {
      color: $black;
      background: $primary;
    }
  }

  .label {
    font-size: $form-checkbox-label-mobile-font-size;
    color: $form-checkbox-label-color;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    transition: color 0.3s;
    cursor: pointer;
    gap: 1rem;
  }

  .checkbox:checked + .label {
    color: $form-input-text-color;
  }

  @media (min-width: 1200px) {
    .label {
      font-size: $form-checkbox-label-desktop-font-size;

      &:hover {
        color: $form-input-text-color;
      }
    }
  }
}
